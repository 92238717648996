import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const copiedText = ({ open, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={700}
    >
      <Alert severity="success">copied</Alert>
    </Snackbar>
  );
};

export default copiedText;
