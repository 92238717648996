import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  Button,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import WebOwner from "../webowner/messages";
import {
  BaseUrl,
  Admin,
  login,
  GetAdmin,
  org,
  profile,
  company,
  companyLogin,
  auth_login,
} from "../../constant/apipath";
import { TramOutlined } from "@mui/icons-material";

const Home = ({ socket }) => {
  const navigate = useNavigate();

  const loginId = document
    .getElementById("chat-admin")
    .getAttribute("data-account");

  const [adminId, setAdminId] = useState(socket.id);
  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [EmailErr, setEmailErr] = useState("");
  const [PasswordErr, setPassErr] = useState("");
  const [errorMsg, setErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [toaster, setToaster] = useState("");
  const currentEmail = localStorage.getItem("adminEmail");

  const token = localStorage.getItem("token");
  useEffect(() => {
    socket.on("connect", () => {
      setAdminId(socket.id);
    });
  }, [socket]);

  useEffect(() => {
    if (loginId) {
      const firstName = "";
      const lastName = "";
      const supportSystem = "";
      const email = Email;
      const admin = true;
      axios({
        method: "post",
        url: BaseUrl + Admin + auth_login,
        data: {
          id: loginId,
        },
      })
        .then((response) => {
          localStorage.setItem("userType", response.data?.type);
          if (response.data.success === true) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("adminEmail", response.data.adminUser?.email);
            setOpen(true);

            if (response.data?.type === "S") {
              localStorage.setItem("depId", 0);
              localStorage.setItem("adminId", response.data.adminUser?.id);
              localStorage.setItem("cmpId", response.data.adminUser?.id);
              socket.emit("join_room", {
                firstName,
                lastName,
                supportSystem,
                email: response.data.adminUser?.email,
                userType: response.data?.type,
                adminId,
                password,
                supId: 0,
                compId: response.data?.adminUser?.id,
              });

              navigate(`/chat`, {
                replace: true,
                state: {
                  mail: Email,
                  depId: 0,
                  webId: response.data?.adminUser?.id,
                  adminId: "superAdmin",
                },
              });
            }

            if (response.data?.type === "E") {
              axios({
                method: "post",
                url: BaseUrl + org + profile,
                data: {
                  companyId: response.data?.adminUser.id,
                },
              })
                .then((res) => {
                  if (res.data.profile) {
                    localStorage.setItem(
                      "depId",
                      res.data?.profile?.department
                    );
                    localStorage.setItem(
                      "adminId",
                      res.data?.profile?.company_employee_id
                    );
                    localStorage.setItem(
                      "cmpId",
                      res.data?.profile?.company_id
                    );
                    socket.emit("join_room", {
                      firstName,
                      lastName,
                      supportSystem,
                      email,
                      admin,
                      adminId,
                      password,
                      userType: response.data.type,
                      supId: res.data?.profile?.department,
                      compId: res.data?.profile?.company_id,
                    });
                    setToaster(response.data.message);
                    navigate(`/chat`, {
                      replace: true,
                      state: {
                        mail: Email,
                        depId: res.data?.profile?.department,
                        webId: res.data?.profile?.company_id,
                        adminId: res.data?.profile?.company_employee_id,
                      },
                    });
                  }
                })
                .catch((error) => {
                  console.log("logerror", error);
                });
            }
          }
        })
        .catch((error) => {
          console.log("errorr", error);
        });
    }
  }, []);

  const joinRoom = (event) => {
    const firstName = "";
    const lastName = "";
    const supportSystem = "";
    const email = Email;
    const admin = true;
    if (Email && password) {
      axios({
        method: "post",
        url: BaseUrl + Admin + login,
        data: {
          email: email,
          password: password,
        },
      })
        .then(async (response) => {
          localStorage.setItem("userType", response.data?.type);
          if (response.data.success === true) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("adminEmail", Email);
            setOpen(true);

            if (response.data?.type === "S") {
              localStorage.setItem("depId", 0);
              localStorage.setItem("adminId", response.data.adminUser?.id);
              localStorage.setItem("cmpId", response.data.adminUser?.id);
              socket.emit("join_room", {
                firstName,
                lastName,
                supportSystem,
                email,
                userType: response.data?.type,
                adminId,
                password,
                supId: 0,
                compId: response.data?.adminUser?.id,
              });

              navigate(`/chat`, {
                replace: true,
                state: {
                  mail: Email,
                  supportSystem: supportSystem,
                  depId: 0,
                  webId: response.data?.adminUser?.id,
                  adminId: "superAdmin",
                },
              });
            }

            if (response.data?.type === "E") {
              await axios
                .get(BaseUrl + Admin + GetAdmin + Email)
                .then((data) => {
                  axios({
                    method: "post",
                    url: BaseUrl + org + profile,
                    data: {
                      companyId: response.data?.adminUser.id,
                    },
                  })
                    .then(async (res) => {
                      // console.log("profile", res);
                      if (res.data.profile) {
                        localStorage.setItem(
                          "depId",
                          res.data?.profile?.department
                        );
                        localStorage.setItem(
                          "adminId",
                          res.data?.profile?.company_employee_id
                        );
                        localStorage.setItem(
                          "cmpId",
                          res.data?.profile?.company_id
                        );
                        socket.emit("join_room", {
                          firstName,
                          lastName,
                          supportSystem,
                          email,
                          admin,
                          adminId,
                          password,
                          userType: response.data.type,
                          supId: res.data?.profile?.department,
                          compId: res.data?.profile?.company_id,
                        });

                        navigate(`/chat`, {
                          replace: true,
                          state: {
                            mail: Email,
                            supportSystem: supportSystem,
                            depId: res.data?.profile?.department,
                            webId: res.data?.profile?.company_id,
                            adminId: res.data?.profile?.company_employee_id,
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      console.log("logerror", error);
                    });
                })
                .catch((err) => console.log("err", err));
            }

            setToaster(response.data.message);
          }
        })
        .catch((error) => {
          setErrMsg(error.response?.data.message);
          if (error?.response?.data?.message === "Unauthorized!") {
            // navigate(`/`, { replace: true });
          }
        });
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (Email === "") {
        setEmailErr("You must enter your email");
      } else if (!reg.test(Email)) {
        setEmailErr("You must enter your valid email");
      } else if (password === "") {
        setPassErr("You must enter your Password");
      }
    }
  };

  return (
    <div>
      {currentEmail && loginId && token ? (
        navigate(`/chat`, { replace: true })
      ) : (
        <div className={styles.container}>
          <Card
            elevation={8}
            sx={{ width: "20%", padding: "20px 70px 50px 50px" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>
                Log In
              </Typography>
            </div>
            <br />
            <div>
              <TextField
                style={{ width: "100%", color: "black" }}
                type="text"
                name="Email"
                error={EmailErr}
                helperText={EmailErr}
                placeholder="Enter a Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErr(false);
                  setErrMsg(false);
                }}
              />
            </div>
            <br />

            <div>
              <TextField
                style={{ width: "100%", color: "black" }}
                type="password"
                name="Password"
                error={PasswordErr}
                helperText={PasswordErr}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPassErr(false);
                  setErrMsg(false);
                }}
                placeholder="Enter a Password"
              />
            </div>
            <br />
            <br />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                onClick={joinRoom}
                variant="contained"
                style={{
                  backgroundColor: "red",
                  backgroundImage: "linear-gradient(90deg,#2f25f7,#3fbbfb)",
                  width: "60%",
                  fontWeight: "bold",
                }}
              >
                Join Room
              </Button>
            </div>
            {/* <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                onClick={adminJoin}
                variant="contained"
                style={{
                  backgroundColor: "red",
                  backgroundImage: "linear-gradient(90deg,#2f25f7,#3fbbfb)",
                  width: "60%",
                  fontWeight: "bold",
                }}
              >
                Join Room
              </Button>
            </div> */}

            <div
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>{errorMsg}</Typography>
            </div>
          </Card>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={900}
          >
            <Alert severity="success">{toaster}</Alert>
          </Snackbar>
        </div>
      )}
    </div>
  );
};

export default Home;
