import incomingMessageSound from "../../assets/sounds/notification.mp3";
import inComingChatUser from "../../assets/sounds/inCommingRing.mp3";
import AdminMessageTone from "../../assets/sounds/adminNotify.mp3";

export async function playIncomingMessageSound() {
  var audio = await new Audio(incomingMessageSound);
  audio.play();
}

export async function playIncomingUSer() {
  var audio = await new Audio(inComingChatUser);
  audio.play();
}

export async function PlayAdminMessage() {
  var audio = await new Audio(AdminMessageTone);
  audio.play();
}
// export function
