import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Badge,
  Typography,
  Accordion,
  TextField,
  Button,
  Switch,
  styled,
} from "@mui/material";
import {
  BaseUrl,
  Admin,
  WebUrl,
  GetAdmin,
  adminUpdate,
} from "../../constant/apipath";
import axios from "axios";
import { Styles } from "../../constant/styles";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CreateIcon from "@mui/icons-material/Create";

export default function Settings() {
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const adminEmail = localStorage.getItem("adminEmail");

  const [AdminUsers, setAdminUsers] = useState([]);
  const [settingsPanelExpanded, setSettingsPanelExpanded] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editValue, setEditVal] = useState("");
  const [editName, setEditName] = useState("");

  const [profileStatus, setProfilestatus] = useState(false);

  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = () => {
    axios
      .get(BaseUrl + Admin + GetAdmin + adminEmail)
      .then(function (data) {
        setAdminUsers(data.data.adminUser);
      })
      .catch((err) => console.log("err", err));
  };

  const updateAdmin = () => {
    if (editable) {
      axios({
        method: "put",
        url: BaseUrl + Admin + adminUpdate + "/" + AdminUsers?.email,
        data: {
          first_name: editName,
        },
      })
        .then(async (response) => {
          if (response.data.success === true) {
            setEditable(false);
            setEditVal(editName);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setEditable(true);
    }
  };

  const handleAccordian3 = (panel) => (event, isExpanded) => {
    setSettingsPanelExpanded(isExpanded ? panel : false);
  };

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", options);
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <Box>
      <Box className="settings-container">
        <Typography variant="h6">Settings</Typography>
        <Box className="profile-avatar-container">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <SmallAvatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                <CreateIcon />
              </SmallAvatar>
            }
          >
            <Avatar alt="Travis Howard" sx={Styles.profileIcon} />
          </Badge>
        </Box>
        <Typography
          className="settingprofilename"
          align="center"
          variant="body1"
        >
          Admin
        </Typography>
        <Box
          className="status-container"
          onClick={() => setProfilestatus(!profileStatus)}
        >
          <Typography
            className="name"
            variant="body1"
            sx={{ color: "#8d84a0" }}
          >
            Available
          </Typography>
          <KeyboardArrowDownIcon fontSize="5px" style={{ color: "#8d84a0" }} />
          {profileStatus && (
            <Box className="profile-status">
              <MenuItem>Available</MenuItem>

              <MenuItem>Busy</MenuItem>
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      <Box className="accordians-container">
        <Accordion
          expanded={settingsPanelExpanded === "settingsPanel1"}
          onChange={handleAccordian3("settingsPanel1")}
          sx={{ backgroundColor: "#f5f7fb" }}
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box className="accordian-center">
              <Typography variant="subtitle2">Personal Info</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
            <Box className="detail">
              <Box className="name-edit">
                <Typography variant="body2" sx={{ color: "#909dbc" }}>
                  Name
                </Typography>
                <div
                  onClick={() => {
                    updateAdmin();
                  }}
                >
                  <Button
                    sx={{ height: "20px", backgroundColor: "#e6ebf5" }}
                    startIcon={
                      <BorderColorIcon
                        style={{ fontSize: "14px" }}
                        size="small"
                      />
                    }
                  >
                    Edit
                  </Button>
                </div>
              </Box>
              {editable ? (
                <TextField
                  variant="standard"
                  value={editName}
                  type="text"
                  onChange={(e) => setEditName(e.target.value)}
                />
              ) : (
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {editValue ? editValue : AdminUsers?.first_name}
                </Typography>
              )}
            </Box>
            <Box className="detail">
              <Typography variant="body2" sx={{ color: "#909dbc" }}>
                Email
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                {AdminUsers?.email ? AdminUsers?.email : adminEmail}
              </Typography>
            </Box>
            <Box className="detail">
              <Typography variant="body2" sx={{ color: "#909dbc" }}>
                Time
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                {formatDateFromTimestamp(AdminUsers?.createdAt).slice(11)}
              </Typography>
            </Box>
            <Box className="detail">
              <Typography variant="body2" sx={{ color: "#909dbc" }}>
                Location
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                california , USA
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          expanded={settingsPanelExpanded === "settingsPanel2"}
          onChange={handleAccordian3("settingsPanel2")}
          sx={Styles.accordian2}
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box className="accordian-center">
              <Typography variant="subtitle2">Privacy</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
            <Box
              className="detail privacy"
              onClick={() => setProfilepic(!profilepic)}
            >
              <Typography variant="caption">Profile photo</Typography>
              <Button
                sx={Styles.selectDropdown}
                size="small"
                endIcon={<KeyboardArrowDownIcon />}
              >
                Everyone
              </Button>
              {profilepic && (
                <Box className="profilepic-Edit">
                  <MenuItem>Everyone</MenuItem>

                  <MenuItem>Selected</MenuItem>
                  <MenuItem>Nobody</MenuItem>
                </Box>
              )}
            </Box>
            <Box className="detail privacy">
              <Typography variant="caption">Last seen</Typography>
              <Switch size="small" />
            </Box>
            <Box
              className="detail privacy"
              onClick={() => setPrivacystatus(!privacystatus)}
            >
              <Typography variant="caption">Status</Typography>

              <Button
                sx={Styles.selectDropdown}
                size="small"
                endIcon={<KeyboardArrowDownIcon />}
              >
                Everyone
              </Button>
              {privacystatus && (
                <Box className="profile-privacy-status">
                  <MenuItem>Everyone</MenuItem>

                  <MenuItem>Selected</MenuItem>
                  <MenuItem>Nobody</MenuItem>
                </Box>
              )}
            </Box>

            <Box className="detail privacy">
              <Typography variant="caption">Read receipts</Typography>
              <Switch size="small" />
            </Box>
            <Box
              className="detail privacy"
              onClick={() => setPrivacygroup(!privacygroup)}
            >
              <Typography variant="caption">Groups</Typography>

              <Button
                sx={Styles.selectDropdown}
                size="small"
                endIcon={<KeyboardArrowDownIcon />}
              >
                Everyone
              </Button>
              {privacygroup && (
                <Box className="profile-privacy-group">
                  <MenuItem>Everyone</MenuItem>
                  <MenuItem>Selected</MenuItem>
                  <MenuItem>Nobody</MenuItem>
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion> */}

        <Accordion
          expanded={settingsPanelExpanded === "settingsPanel3"}
          onChange={handleAccordian3("settingsPanel3")}
          sx={Styles.accordian2}
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box className="accordian-center">
              <Typography variant="subtitle2">Security</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
            <Box className="detail">
              <Box className="name-edit">
                <Typography variant="subtitle2">
                  Show security Notifications
                </Typography>
                <Switch size="small" />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={settingsPanelExpanded === "settingsPanel4"}
          onChange={handleAccordian3("settingsPanel4")}
          sx={Styles.accordian2}
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box className="accordian-center">
              <Typography variant="subtitle2">Help</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
            <Box className="detail">
              <Typography variant="body2" sx={Styles.helpContents}>
                <a href="https://www.iboostweb.com/faq">FAQ</a>
              </Typography>
              <Typography variant="body2" sx={Styles.helpContents}>
                <a href="https://www.iboostweb.com/contact"> Contact </a>
              </Typography>
              <Typography variant="body2" sx={Styles.helpContents}>
                <a href="https://www.iboostweb.com/terms-of-use">
                  Terms & Privacy Policy
                </a>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
