import MessagesReceived from "./messages";

const Chat = ({
  username,
  email,
  supportSystem,
  socket,
  firstName,
  lastName,
  admin,
  isViewerOpen,
  imagesArray,
  messageId,
}) => {
  return (
    <>
      <div>
        <MessagesReceived
          admin={admin}
          socket={socket}
          username={username}
          supportSystem={supportSystem}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      </div>
    </>
  );
};

export default Chat;
