import React, { useState } from "react";
import { BaseUrl, small } from "../../constant/apipath";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { styled } from "@mui/system";
import { Badge } from "@mui/material";
import FsLightbox from "fslightbox-react";

const ImageContainer = ({ imagesArray, isViewerOpen }) => {
  return (
    <FsLightbox
      key={imagesArray}
      toggler={isViewerOpen}
      sources={imagesArray}
    />
  );
};

export default ImageContainer;
