import React, { useState, useEffect } from "react";
import "./App.css";
import "./inline.css";
import WebHome from "./pages/owner_home";
import WebOwner from "./pages/webowner";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Manager } from "socket.io-client";
import { BaseUrl } from "./constant/apipath";
function App() {
  const manager = new Manager(BaseUrl);
  const socket = manager.socket("/");

  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<WebHome socket={socket} />} />
        <Route path={`/chat`} element={<WebOwner socket={socket} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
