export const BaseUrl = "https://api.iboostweb.com";
// export const BaseUrl = "http://localhost:4000";
export const ApiUrl = "/chats";
export const Recent_Post = "/getrecent_post";
export const UserApi = "/users";
export const GetFiles = "/get_files/";
export const small = "/small/";
export const medium = "/medium/";
export const User = "/users";
export const apiUser = "/get_user/";
export const dltMsg = "/delete_msg/";
export const WebUrl = "/web";
export const GetUser = "/get-user/";
export const UpdateUser = "/update-user/";
export const UserUpdate = "/user_update/";
export const ChatUpdate = "/update";
export const Admin = "/admin";
export const GetAdmin = "/get_admin/";
export const adminUser = "/admin_user";
export const login = "/login";
export const adminUpdate = "/admin_update";
export const org = "/org";
export const tagUrl = "/tags";
export const profile = "/profile";
export const company = "/company";
export const companyLogin = "/super_admin_login";
export const auth_login = "/auth_auto_login";

export const webId = localStorage.getItem("cmpId");
export const depId = localStorage.getItem("depId");

export const sendLoad = {
  percent: 100,
  colorCircle: "#3399cc",
  fontColor: "black",
  fontWeight: 300,
  size: 27,
  stroke: 10,
  speed: 100,
  rotation: -90,
  opacity: 8,
  // top: -10,
  animationOff: false,
  inverse: false,
  round: false,
  number: false,
  linearGradient: ["rgb(230, 235, 245)"],
};
