import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Box, Tooltip } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import SmsIcon from "@mui/icons-material/Sms";
import SettingsIcon from "@mui/icons-material/Settings";

const Bottom = ({ value, handleValueChange, handleContentChange }) => {
  return (
    <div className="Bottom-nav">
      <Box sx={{ width: "100%" }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            handleValueChange(newValue);
          }}
        >
          <Tooltip title="profile" arrow placement="top">
            <BottomNavigationAction
              value={value}
              onClick={() => handleContentChange("profile")}
              icon={<PersonOutlineRoundedIcon />}
            />
          </Tooltip>

          <Tooltip title="chats" arrow placement="top">
            <BottomNavigationAction
              value={value}
              onClick={() =>
                handleContentChange("chat")
              }
              icon={<SmsIcon />}
            />
          </Tooltip>

          <Tooltip title="settings" arrow placement="top">
            <BottomNavigationAction
              value={value}
              onClick={() =>
                handleContentChange("setting")
              }
              icon={<SettingsIcon />}
            />
          </Tooltip>
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default Bottom;
