import React from "react";
import { Alert ,Box,Avatar,Typography} from "@mui/material";
import { Styles } from "../../constant/styles";
import WarningSharpIcon from "@mui/icons-material/WarningSharp";




const DeleteComp = ({ items, name,usersName }) => {
  return (
    <Box sx={{ display: "flex", gap: "0.5rem" }}>
    {items === "G" ? (
      <Avatar sx={Styles.Messageavatar}>{usersName.charAt(0)}</Avatar>
    ) : null}

    <Box className="delete-messgae">
      <WarningSharpIcon fontSize="16px" style={{ color: "#d0c462" }} />
      <Typography variant="subtitle2" sx={{ ml: 1 }}>
        You deleted this message
      </Typography>
    </Box>
    {items !== "G" ? (
      <Avatar sx={Styles.AdminAvatar}>{usersName.charAt(0)}</Avatar>
    ) : null}
  </Box>  );
};

export default DeleteComp;
