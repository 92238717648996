import React from "react";
import { Box,Typography,IconButton } from "@mui/material";
import { FileIcon, defaultStyles } from "react-file-icon";
import { CloseOutlined } from "@mui/icons-material";
import { Styles } from "../../constant/styles";



const selectedFile = ({ files, removeImage }) => {
  return (
    <div style={{ width: "80%" }}>
    {files.length > 0 ? (
      <Box className="preview-container">
        {files.map((file, index) => {
          return (
            <>
              {file !== "" ? (
                <Box className="file_attachcontainerr">
                  {file &&
                  file.fileType &&
                  file.fileType.split("/")[0] ===
                    "image" ? (
                    <>
                      <div className="hover_img">
                        <div className="btn-closeContainer">
                          <IconButton
                            size="small"
                            onClick={() => {
                              removeImage(file);
                            }}
                          >
                            <CloseOutlined className="imgClose-Icon" />
                          </IconButton>
                        </div>
                        <img
                          height={90}
                          width={90}
                          src={file.src}
                          alt=""
                          className="selected-file"
                        />
                      </div>
                      <Typography sx={Styles.fileList}>
                        {file && file.name
                          ? file.name.substring(0, 5) +
                            "..." +
                            file.name.slice(-8)
                          : ""}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <div className="hover_img">
                        <div className="btn-closeContainer">
                          <IconButton
                            size="small"
                            onClick={() => {
                              removeImage(file);
                            }}
                          >
                            <CloseOutlined className="imgClose-Icon" />
                          </IconButton>
                        </div>
                        <div
                          style={Styles.fileContainer}
                        >
                          <FileIcon
                            extension={
                              file.name.split(".")[1]
                            }
                            {...defaultStyles[
                              file.name.split(".")[1]
                            ]}
                          />
                        </div>
                      </div>

                      <Typography
                        sx={Styles.fileListFile}
                      >
                        {file && file.name
                          ? file.name.substring(0, 4) +
                            "..." +
                            file.name.slice(-8)
                          : ""}
                      </Typography>
                    </>
                  )}
                </Box>
              ) : null}
            </>
          );
        })}
      </Box>
    ) : null}
  </div>
  );
};

export default selectedFile;
