import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import {
  Grid,
  Badge,
  ButtonBase,
  Typography,
  Input,
  Card,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Button from "@mui/material/Button";
import FiberManualRecordTwoToneIcon from "@mui/icons-material/FiberManualRecordTwoTone";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { PlayAdminMessage } from "../sound/sound";
import { playIncomingUSer } from "../sound/sound";
import {
  BaseUrl,
  ApiUrl,
  WebUrl,
  ChatUpdate,
  Recent_Post,
  small,
  adminUser,
  sendLoad,
  org,
  tagUrl,
} from "../../constant/apipath";
import { Styles } from "../../constant/styles";
import { styled } from "@mui/system";
import { useState, useEffect, useRef } from "react";
import React from "react";
import AdminLogout from "./AdminLogout";
import UserProfile from "./UserProfile";
import AdminProfile from "./AdminProfile";
import DrawerContent from "./Drawer";
import Settings from "./Settings";
import CopiedText from "./copiedText";
import ImageContainer from "./imageViewer";
import FileContainer from "./fileContainer";
import SendMessageContainer from "./sendMessageContainer";
import ResponsiveBottom from "./responsiveBottom";
import SendIcon from "@mui/icons-material/Send";
import AppHeader from "./AppHeader";
import SelectedFiles from "./selectedFile";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import UserIncome from "./userIncome";
import DeleteComp from "./deleteComp";
import EmojiConvertor from "emoji-js";
import useMediaQuery from "@mui/material/useMediaQuery";

const Messages = ({ username, room, socket, firstName, lastName }) => {
  const { state } = useLocation();

  const [value, setValue] = React.useState("recents");
  const [tags, setTags] = useState([]);
  const currentEmail = localStorage.getItem("adminEmail");
  const token = localStorage.getItem("token");
  const currentAdmin = localStorage.getItem("adminId");

  const loginId = document
    .getElementById("chat-admin")
    .getAttribute("data-account");

  const [showNav, setShowNav] = useState(false);
  const [messagesRecieved, setMessagesReceived] = useState([]);
  const [recentChat, setRecentChat] = useState([]);
  const messagesColumnRef = useRef(null); // Add this
  const [message, setMessage] = useState();
  const [userId, setUserId] = useState("");
  const [email, setUsermail] = useState("");
  const [True, settrue] = useState(false);
  const [emailUsers, setEmailUser] = useState("");
  const [idvalue, setIDvalue] = useState("");
  const [roomValues, setRoomValues] = useState("");
  const [typingUsers, setTypingUsers] = useState([]);
  const [adminId, setAdminId] = useState(socket.id);
  const [onlinestatus, setOnlineStatus] = useState("");
  const [usersName, setUserName] = useState("");
  const [files, SetFiles] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [adOpen, setAdOpen] = useState(false);
  const [Check, setCheck] = useState(false);
  const [load, setLoad] = useState(false);
  const [mbDawer, setMbDawer] = useState(false);
  const [myData, setMyData] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [messageLoad, setMessageLoad] = React.useState(true);
  const [msgScrollHeight, setMsgScrollHeight] = React.useState(null);
  const [scrollValue, setScrollValue] = useState([]);
  const [cancelUser, setCancelUser] = useState(false);
  const [typingSts, setTypingSts] = useState("");
  const [id, setId] = useState("");
  const [fileId, setFileId] = useState("");

  const openMenu = Boolean(anchorEl);
  const moment = require("moment");
  const webId = localStorage.getItem("cmpId");
  const depId = localStorage.getItem("depId");
  const userType = localStorage.getItem("userType");

  const navigate = useNavigate();

  const RemoveEmail = () => {
    localStorage.removeItem("adminEmail");
    localStorage.removeItem("adminId");
    localStorage.removeItem("userType");
    localStorage.removeItem("token");
    localStorage.removeItem("supSys");
    localStorage.removeItem("cmpId");
    localStorage.removeItem("depId");
    navigate(`/`, { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    if (loginId === "") {
      RemoveEmail();
    }
  }, [loginId]);

  const matches = useMediaQuery("(max-width:900px)");
  const mbview = useMediaQuery("(max-width:48px)");

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };
  const handleContentChange = (value) => {
    setContent(value);
  };
  const emojiConvertor = new EmojiConvertor();
  emojiConvertor.init_env();

  useEffect(() => {
    setShowNav(false);
  }, [matches]);

  const handleMobileClose = () => {
    settrue(false);
  };

  const dawerOpen = () => {
    setMbDawer(true);
  };

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", options);
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const userEmail = localStorage.getItem("user_email");

  const set_show = () => {
    setShowNav(true);
  };

  const show_method = () => {
    setShowNav(false);
  };

  const admin_logoutSetting = () => {
    setContent("setting");
  };

  const admin_logoutProfile = () => {
    setContent("profile");
  };

  const [next, setNext] = useState(10);

  const [content, setContent] = useState("chat");
  const [fetchData, setFetch] = useState(false);

  useEffect(() => {
    if (messagesColumnRef.current && messageLoad) {
      messagesColumnRef.current.scrollTop =
        messagesColumnRef.current.scrollHeight;
    }
  }, [messagesRecieved, messageLoad]);

  const handleScroll = (e) => {
    let element = e.target;
    setFetch(true);
    if (element.scrollTop === 0 && next <= scrollValue) {
      setFetch(true);
      setNext(next + 10);
      setMyData(false);
      setMsgScrollHeight(messagesColumnRef.current.scrollHeight);
      setMessageLoad(false);
      getUserChat(email).then(() => {
        const scrollTo = messagesColumnRef.current.scrollHeight;
        messagesColumnRef.current.scrollTop = scrollTo;
      });
    } else {
      setFetch(false);
    }
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#3399cc",
        contrastText: "#fff",
      },
    },
  });

  useEffect(() => {
    socket.on("connect", () => {
      setAdminId(socket.id);
      const email = currentEmail;
      const firstName = "";
      const lastName = "";
      const supportSystem = "";
      const admin = true;
      socket.emit("join_room", {
        firstName,
        lastName,
        supportSystem,
        email,
        admin,
        userType: userType,
        adminId: socket.id,
        supId: depId,
        compId: webId,
      });
    });
    return () => socket.off("join_room");
  }, [socket]);

  useEffect(() => {
    getRecentUser();
    const emailUser = localStorage.getItem("id");
    setEmailUser(emailUser);
  }, [emailUsers, adminUser]);

  const updateStatus = async (mail) => {
    axios({
      method: "put",
      url: BaseUrl + ApiUrl + ChatUpdate + "/" + mail,
      headers: {
        Authorization: token,
      },
      data: {
        seen_status: 1,
      },
    })
      .then(async (response) => {
        getRecentUser();
      })
      .catch((error) => {
        if (error?.response.data?.message === "Unauthorized!") {
          RemoveEmail();
        }
      });
  };

  useEffect(() => {
    socket.on("typingStatus", (users) => {
      var array = [];
      recentChat.map((items) => {
        if (items.userid === users.userId) {
          items["typeuser"] = users.type;
          setTypingSts(users.typeletter);
          items["typeletter"] = users.typeletter;
        }
        array.push(items);
      });
      setRecentChat([...array]);
      setTypingUsers(users);
    });
  }, [recentChat, idvalue, socket]);

  useEffect(() => {
    socket.on("reconnect", (data) => {
      setMyData(false);
      getRecentUser();
    });
  }, []);

  useEffect(() => {
    socket.on("LeaveUsers", (data) => {
      getRecentUser();
      if (data.email === email) {
        setOnlineStatus("offline");
      }
    });
  }, [onlinestatus]);

  useEffect(() => {
    socket.on("Accept", (data) => {
      getRecentUser();
    });
    socket.on("Cancel", (data) => {
      setCancelUser(true);
      getRecentUser();
    });
    socket.on("DeleteChat", (data) => {
      setMyData(true);
      getUserChat(data.email);
    });

    socket.on("IdleUsers", (data) => {
      if (data.email === email) {
        setOnlineStatus("Idle");
      }
      getRecentUser();
    });
    socket.on("Active", (data) => {
      getRecentUser();
    });
  }, [socket]);

  const handleFileId = () => {
    setFileId(null);
  };

  const sendMessage = (e) => {
    const author = "me";
    const data = { text: message };
    const type = "text";
    const admin = "admin";
    const __createdtime__ = Date.now();
    if (message.trim() || files != "") {
      setLoad(true);
      if (message != "" || files != "") {
        const count = 0;
        socket.emit("send_message", {
          count,
          author,
          data,
          type,
          username,
          room,
          message,
          __createdtime__,
          firstName,
          lastName,
          admin,
          userId,
          adminId,
          userType,
          email,
          files,
          supId: depId,
          compId: webId,
          id: currentAdmin,
        });
        setMessage("");
      }
    }
    socket.emit(message != "" ? "stopadminType" : "", {
      idvalue,
      adminId: currentAdmin,
      supId: depId,
      compId: webId,
      userType: userType,
    });
    SetFiles([]);
  };

  const [tagValue, setTagValue] = useState([]);
  const handleEmoji = (e, value) => {
    const searchValue = e;
    const filterValues = tags?.filter((item, index) =>
      item.tag.toLowerCase().includes(e.toLowerCase())
    );
    if (searchValue.length > 0) {
      setTagValue(filterValues);
    } else {
      setTagValue([]);
    }
    setMessage(e);
    const istyping = e;
    socket.emit(istyping ? "startadminType" : "stopadminType", {
      idvalue,
      email: email,
      userType: userType,
      adminId: currentAdmin,
      supId: depId,
      compId: webId,
    });
  };

  useEffect(() => {
    socket.on("chatvalue", (data) => {
      setUserId(data.id);
      if (data.email === email) {
        setOnlineStatus("online");
      }
      if (userEmail === data.email) {
        setCheck(true);
        setCancelBtn(true);
      }
      playIncomingUSer();
      getRecentUser();
    });
  }, [socket, email]);

  useEffect(() => {
    socket.on("adminreceiver_message", (data) => {
      if (data.files || data.postimages) {
        setLoad(false);
      }
      getRecentUser();
      if (data.email === email) {
        updateStatus(id);
      }
      setMessagesReceived((state) => [
        ...state,
        {
          message: data && data.message ? data.message : null,
          email: data.email,
          firstName: data.firstname,
          lastName: data.lastname,
          admin: data.admin,
          from_to: data.admin === "admin" ? "E" : "G",
          userid: data.userid,
          __createdtime__: data.__createdtime__,
          createdAt: data.createdAt,
          attachedFile: data.attachedFile,
          postimages: data.files ? data.files : data.postimages,
          createdtime: data.__createdtime__,
        },
      ]);
      setMessageLoad(true);
      if (data && !data.admin) {
        PlayAdminMessage();
      }
      getUserChat(data.email);
      setMyData(false);
    });
    return () => socket.off("adminreceiver_message");
  }, [recentChat, load, socket]);

  const getUse = (datas) => {
    const array = datas.reverse();
    setMessagesReceived(array);

    // array.reduce((prev, curr) => {
    //   console.log("prev", prev);
    //   if (prev && prev.user_id !== curr.user_id) {
    //     console.log("chat ended");
    //     // setIndexChat("chat ended");
    //   }
    //   console.log("curr", curr);
    //   return curr;
    // }, null);

    // function groupBy(objectArray, property) {
    //   return objectArray.reduce(function (accumulator, currentObject) {
    //     let key = currentObject[property];
    //     if (!accumulator[key]) {
    //       accumulator[key] = [];
    //     }
    //     accumulator[key].push(currentObject);
    //     return accumulator;
    //   }, {});
    // }

    // let groupedPeople = groupBy(array, "user_id");

    // Object.keys(groupedPeople).forEach((f, index) => {
    //   if (index !== 0) {
    //     // console.log("chat ended");
    //     setIndexChat("chat ended");
    //     // return "chat Ended";
    //   } else {
    //     groupedPeople[f].map((m) => {
    //       arr.push(m);
    //       var arrays = arr.reverse();
    //       setMessagesReceived(arrays);
    //       // console.log(m);
    //     });
    //   }
    // });
  };

  const getUserChat = async (oneUser, e) => {
    axios({
      method: "post",
      url: BaseUrl + ApiUrl + WebUrl,
      headers: {
        Authorization: token,
      },
      data: {
        email: oneUser,
        limit: next,
      },
    })
      .then(async (response) => {
        getUse(response.data.allChats);
      })
      .catch((error) => {
        if (error?.response.data?.message === "Unauthorized!") {
          RemoveEmail();
        }
        console.log("error", error);
      });
  };

  useEffect(() => {
    getChat();
    axios({
      method: "post",
      url: BaseUrl + org + tagUrl,
      data: {
        companyId: webId,
      },
    })
      .then(async (response) => {
        setTags(JSON.parse(response.data.department.tags));
      })
      .catch((error) => {
        console.log("tagerror", error);
      });
  }, [email, state]);

  const getChat = async (oneuser, e) => {
    axios({
      method: "post",
      url: BaseUrl + ApiUrl + WebUrl,
      headers: {
        Authorization: token,
      },
      data: {
        email: email,
        limit: null,
      },
    })
      .then(async (response) => {
        setScrollValue(response.data?.allChats?.length);
      })
      .catch((error) => {
        if (error?.response.data?.message === "Unauthorized!") {
          RemoveEmail();
        }
        console.log("error", error);
      });
  };

  const getRecentUser = async (oneuser, e) => {
    await axios({
      method: "post",
      url: BaseUrl + ApiUrl + Recent_Post,
      data: {
        currentAdmin: currentAdmin,
        userType: userType,
      },
    })
      .then(async (response) => {
        setRecentChat(response.data.allPosts);
        setRoomValues(response.data.allPosts);
      })
      .catch((error) => {
        if (error?.response.data?.message === "Unauthorized!") {
          RemoveEmail();
        }
        console.log("error", error);
      });
  };

  const handleFileUpload = (post) => {
    let files = [];
    if (post.target.files && post.target.files.length > 0) {
      for (let i = 0; i < post.target.files.length; i++) {
        let file = post.target.files[i];
        const fileName = file.name;
        let reader = new FileReader();
        const fileExt =
          fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
          fileName;
        if (file) {
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            files.push({
              fileSource: file,
              src: reader.result,
              fileType: file.type,
              name: file.name,
              size: file.size,
              ext: fileExt,
            });
            SetFiles([...files]);
          };
        }
      }
    }
  };

  const [searchValues, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchValue(event.target.value);
    const filterValues = recentChat.filter((item, index) =>
      item.first_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    if (searchValue.length > 0) {
      setRecentChat(filterValues);
    } else {
      setRecentChat(roomValues);
    }
  };

  function handleOnEnter(text) {
    sendMessage(text);
  }

  const [More, setMore] = useState("");

  const handleMore = (id) => {
    setMore(id);
  };

  const [Dele, setDele] = useState(false);

  const openImageViewer = async (img, id, index) => {
    var arr = [];
    if (img && img.length > 0) {
      await img.forEach((items) => {
        if (items?.fileType?.split("/")[0] === "image") {
          if (items.src) {
            arr.push(items.src);
          } else {
            arr.push(BaseUrl + "/images/" + items.user_id + "/" + items.source);
          }
          setImagesArray([...arr]);
        }
      });
    }
    setIsViewerOpen(!isViewerOpen);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const [timeValue, setTimeValue] = useState("");

  const menuItems = (items, message) => {
    return (
      <Menu
        sx={{
          position: "absolute",
          left: items === "S" ? "-145px" : mbview ? "-122px" : "30px",
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
            navigator.clipboard.writeText(message);
          }}
        >
          Copy Text
          <ListItemIcon style={Styles.DropdownItem}>
            <ContentCopyOutlinedIcon fontSize="medium" />
          </ListItemIcon>
        </MenuItem>
        {userType === "S" ? (
          <MenuItem
            onClick={() => {
              items !== "admin" ? setDele(true) : setAdOpen(true);
            }}
          >
            Delete
            <ListItemIcon style={Styles.DropdownItem}>
              <DeleteOutlineIcon fontSize="medium" />
            </ListItemIcon>
          </MenuItem>
        ) : null}
      </Menu>
    );
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      fontSize: "20px", // Adjust the font size as needed
      marginRight: "-6px",
    },
  }));

  const modalFun = (items, messageMail) => {
    return (
      <Modal open={items !== "admin" ? Dele : adOpen} handleClose={handleClose}>
        <Box sx={Styles.styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure Want to delete this message ?
          </Typography>
          <Box sx={{ backgroundColor: "white", marginTop: 2 }}>
            <Button
              onClick={() => {
                items !== "admin" ? setDele(false) : setAdOpen(false);
                socket.emit("delete_chat", {
                  email: messageMail,
                  userId: userId,
                  msgId: More,
                  userType: userType,
                  adminId: currentAdmin,
                  supId: depId,
                  compId: webId,
                });
              }}
              variant="contained"
            >
              Yes
            </Button>
            <Button
              style={{ marginLeft: "30px" }}
              variant="contained"
              onClick={() => {
                items !== "admin" ? setDele(false) : setAdOpen(false);
                setAnchorEl(false);
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const imageAddItem = (values, message) => {
    const postImagesCount = message.postimages.length;
    return message.postimages.slice(0, values).map((postImg, index) => (
      <div
        onClick={() => {
          openImageViewer(message.postimages, message.id, index);
        }}
        className="image-container"
      >
        {postImg?.fileType?.split("/")[0] === "image" ? (
          <>
            <div className="gallery-item">
              <img
                src={
                  BaseUrl +
                  "/images" +
                  small +
                  message.user_id +
                  "/" +
                  postImg.source
                }
                style={{
                  height: 100,
                  width: 100,
                }}
                alt=""
              />

              {(message.postimages.length > 4 ||
                message.postimages.length === 3) &&
              index === values - 1 ? (
                <div className="image-plus">
                  <AddOutlinedIcon
                    style={{
                      fontSize: "17px",
                      color: "white",
                    }}
                  />
                  <StyledBadge
                    style={{
                      color: "white",
                    }}
                    badgeContent={postImagesCount - values}
                  ></StyledBadge>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    ));
  };

  const moreBtn = (messageId) => {
    return (
      <MoreVertOutlinedIcon
        fontSize="inherit"
        onClick={(event) => {
          handleMore(messageId);
          setAnchorEl(event.currentTarget);
        }}
        sx={{
          cursor: "pointer",
          color: "#3399cc",
        }}
      />
    );
  };

  const removeImage = (file) => {
    SetFiles((oldState) => oldState.filter((item) => item !== file));
  };

  const [cancelBtn, setCancelBtn] = useState(false);
  const [errors, setError] = useState(false);

  const checkFunc = () => {
    setCheck(false);
  };

  const cancelFunc = () => {
    setCancelBtn(false);
    setError(true);
  };

  // let people = [
  //   { name: "John", chatId: 1 },
  //   { name: "Oliver", chatId: 1 },
  //   { name: "Michael", chatId: 1 },
  //   { name: "Dwight", chatId: 2 },
  //   { name: "Oscar", chatId: 2 },
  //   { name: "Kevin", chatId: 3 },
  //   { name: "Ke", chatId: 3 },
  // ];
  // function groupBy(objectArray, property) {
  //   return objectArray.reduce(function (accumulator, currentObject) {
  //     let key = currentObject[property];
  //     if (!accumulator[key]) {
  //       accumulator[key] = [];
  //     }
  //     accumulator[key].push(currentObject);
  //     return accumulator;
  //   }, {});
  // }

  // let groupedPeople = groupBy(people, "chatId");

  // Object.keys(groupedPeople).forEach((f, index) => {
  //   if (index !== 0) {
  //     console.log("chat Ended");
  //   }
  //   groupedPeople[f].map((m) => console.log(m));
  // });

  const setChatEnded = (message) => {
    // var array = [];
    // function groupBy(objectArray, property) {
    //   return objectArray.reduce(function (accumulator, currentObject) {
    //     let key = currentObject[property];
    //     if (!accumulator[key]) {
    //       accumulator[key] = [];
    //     }
    //     accumulator[key].push(currentObject);
    //     return accumulator;
    //   }, {});
    // }
    // let groupedPeople = groupBy(messagesRecieved, "user_id");
    // return Object.keys(groupedPeople).forEach((f, index) => {
    //   if (index !== 0) {
    //     console.log("chat ended");
    //     return "chat Ended";
    //   }
    //   groupedPeople[f].map((m) => {
    //     array.push(m);
    //     setMessagesReceived(array);
    //     console.log(m);
    //   });
    // });
    // if (currChatId !== message.user_id) {
    //   // setCurrChatId(message.user_id);
    //   // return "chat Ended";
    // return "";
  };

  console.log(
    "FIXED VALUES",
    currentEmail === null,
    loginId === "",
    token === null
  );

  return (
    <>
      <div>
        {currentEmail === null && loginId === "" && token === null ? (
          navigate(`/`, { replace: true })
        ) : (
          <>
            <Box sx={{ display: "flex" }}>
              {!matches ? (
                <DrawerContent handleContentChange={handleContentChange} />
              ) : null}

              <div>
                <AdminLogout
                  admin_logoutSetting={admin_logoutSetting}
                  admin_logoutProfile={admin_logoutProfile}
                />
              </div>

              <Grid container>
                <Grid
                  item
                  lg={4}
                  xl={3}
                  sm={12}
                  // md={showNav ? 3 : 4}
                  md={4}
                  xs={12}
                  sx={{
                    bgcolor: "#ffff",
                    borderRight: 1,
                    borderColor: "#f1f1f6",
                  }}
                >
                  {content === "chat" ? (
                    <>
                      <Box className="chat-list-headerr">
                        <Typography variant="h6">Chats</Typography>
                        <Box className="input-container">
                          <Input
                            fullWidth
                            placeholder="Search Messages or Users"
                            sx={Styles.searchInput}
                            value={searchValues}
                            onChange={handleSearchChange}
                            startAdornment={
                              <SearchOutlinedIcon
                                style={Styles.SearchOutlinedIcon}
                              />
                            }
                            disableUnderline
                          />
                        </Box>
                      </Box>
                      <Typography className="recent">Recent</Typography>

                      <div className="scroll-box">
                        <List>
                          {recentChat.map((user, index) => {
                            const readstatus =
                              user && user.chats.length > 0
                                ? user.chats.filter(
                                    (items) =>
                                      items.from_to != "admin" &&
                                      items.seen_status === 0
                                  )
                                : null;
                            const arr =
                              user && user.chats && user.chats.length > 0
                                ? user.chats
                                : user.chats;
                            const last =
                              arr && arr.length > 0 ? arr[0] : "undefined";
                            return (
                              <>
                                {user.first_name !== "admin" ? (
                                  <ButtonBase
                                    className={
                                      user.accept_status === 0 &&
                                      user.online_status !== "offline"
                                        ? "blink"
                                        : ""
                                    }
                                    sx={Styles.buttonBase}
                                    key={"chat" + index}
                                  >
                                    <ListItem
                                      onClick={() => {
                                        setTypingSts("");
                                        handleFileId();
                                        if (user.accept_status === 0) {
                                          setCheck(true);
                                          setCancelBtn(true);
                                        } else {
                                          setCheck(false);
                                        }
                                        setCancelUser(user.accept_status);
                                        setSearchValue("");
                                        setScrollValue(false);
                                        settrue(true);
                                        setShowNav(false);
                                        setTimeValue(
                                          user.createdAt
                                            ? user.createdAt
                                            : last.createdAt
                                        );
                                        localStorage.setItem(
                                          "user_email",
                                          user.email
                                        );

                                        setUserName(user.first_name);
                                        getUserChat(user.email);
                                        setOnlineStatus(user.online_status);
                                        updateStatus(user.id);
                                        setId(user.id);
                                        setUserId(user.userid);
                                        setIDvalue(user.userid);
                                        setUsermail(user.email);
                                      }}
                                      sx={{
                                        backgroundColor:
                                          user.email === email
                                            ? "#deddd9"
                                            : null,
                                      }}
                                      className={
                                        user.joinUSer === true
                                          ? "author_left  hover blink"
                                          : "author_left  hover "
                                      }
                                    >
                                      <ListItemAvatar>
                                        <Avatar sx={Styles.listItemAvatar}>
                                          {user.first_name.charAt(0)}
                                        </Avatar>
                                        <FiberManualRecordTwoToneIcon
                                          style={{
                                            fontSize: "19px",
                                            position: "absolute",
                                            marginLeft: "30px",
                                            marginTop: "-15px",
                                            zIndex: "1",
                                            color:
                                              user.online_status === "online"
                                                ? "#49be25"
                                                : user.online_status === "Idle"
                                                ? "orange"
                                                : "#7a7879",
                                          }}
                                        />
                                      </ListItemAvatar>

                                      {user.typeuser != true ? (
                                        <ListItemText
                                          primary={
                                            <Typography
                                              sx={Styles.primaryText}
                                              noWrap={true}
                                            >
                                              {user.first_name === "admin"
                                                ? null
                                                : user.first_name ||
                                                  user.firstname}
                                            </Typography>
                                          }
                                          secondary={
                                            <Typography
                                              variant="body2"
                                              noWrap={true}
                                              style={Styles.chatMessage}
                                            >
                                              {last && last.chat_message
                                                ? last.chat_message
                                                : "photo"}
                                            </Typography>
                                          }
                                        />
                                      ) : (
                                        <ListItemText
                                          primary={
                                            <Typography
                                              sx={Styles.primaryText}
                                              noWrap={true}
                                            >
                                              {user.first_name === "admin"
                                                ? null
                                                : user.first_name ||
                                                  user.firstname}
                                            </Typography>
                                          }
                                          secondary={
                                            <div style={{ display: "flex" }}>
                                              <Typography
                                                variant="body2"
                                                noWrap={true}
                                                style={Styles.TypeMessage}
                                              >
                                                {user.typeletter}
                                              </Typography>
                                              <div
                                                style={{ marginLeft: "5px" }}
                                                className="chat-bubble"
                                              >
                                                <div className="chat-typing">
                                                  <div className="dot"></div>
                                                  <div className="dot"></div>
                                                  <div className="dot"></div>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                        />
                                      )}
                                      <div className="chat-right">
                                        <Typography variant="caption">
                                          {last?.timestamp
                                            ? moment(last?.timestamp).format(
                                                "LT"
                                              )
                                            : null}
                                        </Typography>

                                        {user.email !== email && (
                                          <>
                                            {readstatus &&
                                            readstatus.length != 0 ? (
                                              <ThemeProvider theme={theme}>
                                                <div style={Styles.badge}>
                                                  <Badge
                                                    color="neutral"
                                                    badgeContent={
                                                      readstatus &&
                                                      readstatus.length > 0
                                                        ? readstatus.length
                                                        : 0
                                                    }
                                                  ></Badge>
                                                </div>
                                              </ThemeProvider>
                                            ) : null}
                                          </>
                                        )}
                                      </div>
                                    </ListItem>
                                  </ButtonBase>
                                ) : null}
                              </>
                            );
                          })}
                        </List>
                      </div>
                    </>
                  ) : content === "profile" ? (
                    <AdminProfile socket={socket} />
                  ) : (
                    <Settings />
                  )}
                </Grid>

                {matches || True ? (
                  <Grid
                    className={
                      True
                        ? "chat-container chat show-chat"
                        : "chat-container show-chat"
                    }
                    item
                    lg={8}
                    md={8}
                    xl={9}
                    sm={12}
                    xs={12}
                  >
                    <div className="chat-container">
                      <AppHeader
                        recentChat={recentChat}
                        email={email}
                        socket={socket}
                        handleMobileClose={handleMobileClose}
                        // setTruFum={setTruFum}
                        // set_show={set_show}
                        show_method={show_method}
                        set_show={set_show}
                        usersName={usersName ? usersName : ""}
                        online_status={onlinestatus}
                      />
                      {fetchData ? (
                        <Box
                          sx={{
                            color: "#fff",
                            backgroundColor: "#fff",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <CircularProgress thickness={4} size={30} />
                        </Box>
                      ) : null}

                      <div
                        onScroll={handleScroll}
                        ref={!myData ? messagesColumnRef : null}
                        className="chat-scrollpage"
                      >
                        {timeValue?.slice(0, 10) ===
                        moment().format().slice(0, 10) ? (
                          timeValue?.slice(0, 10) ===
                          moment()
                            ?.subtract(1, "days")
                            .format()
                            .slice(0, 10) ? (
                            <Divider sx={Styles.divider}>
                              <Typography
                                className="divider-content"
                                sx={Styles.dividercolor}
                                variant="caption"
                              >
                                <div>
                                  {moment.utc(timeValue).format("MMM DD YYYY")}
                                </div>
                              </Typography>
                            </Divider>
                          ) : null
                        ) : (
                          <Divider sx={Styles.divider}>
                            <Typography
                              className="divider-content"
                              sx={Styles.dividercolor}
                              variant="caption"
                            >
                              yesterday
                            </Typography>
                          </Divider>
                        )}

                        {messagesRecieved.map((message) => {
                          return (
                            <>
                              {message.from_to === "G" ? (
                                <>
                                  <div className="covo-left">
                                    {message.delete_count === 1 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          padding: 5,
                                        }}
                                      >
                                        <DeleteComp
                                          items={message.from_to}
                                          name={message.first_name}
                                          usersName={usersName}
                                        />
                                      </div>
                                    ) 
                                    : (
                                    <div className="d-flex flex-gap">
                                        <div>
                                          <Avatar sx={Styles.Messageavatar}>
                                            {usersName.charAt(0)}
                                          </Avatar>
                                        </div>
                                        <div className="chat-left-bubble">
                                          <Card
                                            elevation={3}
                                            style={Styles.cardleftcolor}
                                          >
                                            {message.postimages &&
                                            message.postimages.length > 0 ? (
                                              <>
                                                <div
                                                  style={{
                                                    display: "grid",
                                                    gridTemplateColumns:
                                                      "auto auto",
                                                    gap: "8px",
                                                  }}
                                                >
                                                  {message.postimages.length >=
                                                  4
                                                    ? imageAddItem(4, message)
                                                    : null}

                                                  {message.postimages.length <=
                                                  3
                                                    ? imageAddItem(2, message)
                                                    : null}
                                                </div>
                                              </>
                                            ) : null}

                                            {message.postimages &&
                                            message.postimages.length > 0 ? (
                                              <FileContainer
                                                message={message}
                                              />
                                            ) : null}

                                            <div>
                                              <Typography
                                                sx={{
                                                  fontSize: "15px",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                {message.message}
                                              </Typography>

                                              <div className="AccessTime-left">
                                                <AccessTimeOutlinedIcon
                                                  style={
                                                    Styles.AcessTimeIconLeft
                                                  }
                                                />
                                                <Typography variant="caption">
                                                  {moment(
                                                    message.createdtime
                                                  ).format("LT")}
                                                </Typography>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                        <div className="chat-action-menu">
                                          {moreBtn(message.id)}

                                          {message.id === More ? (
                                            <div
                                              style={{ backgroundColor: "red" }}
                                            >
                                              {menuItems(
                                                message.from_to,
                                                message.message
                                              )}
                                            </div>
                                          ) : null}

                                          {modalFun(message.from_to, email)}
                                        </div>{" "}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div className="convo-right">
                                    {message.delete_count === 1 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          padding: 5,
                                        }}
                                      >
                                        <DeleteComp
                                          items={message.from_to}
                                          name={message.first_name}
                                          usersName={usersName}
                                        />
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-end flex-gap">
                                        {message.id === More ? (
                                          <div>
                                            {menuItems(
                                              message.from_to,
                                              message.message
                                            )}
                                          </div>
                                        ) : null}
                                        {moreBtn(message.id)}
                                        {modalFun(message.from_to, email)}

                                        <div className="chat-right-bubble">
                                          <Card>
                                            <div
                                              elevation={3}
                                              style={Styles.cardrightcolor}
                                            >
                                              {message.postimages &&
                                              message.postimages.length > 0 ? (
                                                <>
                                                  <div
                                                    style={{
                                                      display: "grid",
                                                      gridTemplateColumns:
                                                        "auto auto",
                                                      gap: "8px",
                                                    }}
                                                  >
                                                    {message.postimages
                                                      .length >= 4
                                                      ? imageAddItem(4, message)
                                                      : null}
                                                    {message.postimages
                                                      .length <= 3
                                                      ? imageAddItem(2, message)
                                                      : null}
                                                  </div>
                                                </>
                                              ) : null}

                                              {message.postimages &&
                                              message.postimages.length > 0 ? (
                                                <FileContainer
                                                  message={message}
                                                />
                                              ) : null}

                                              <div>
                                                <Typography
                                                  sx={Styles.receiverMessage}
                                                >
                                                  {message.message}
                                                </Typography>

                                                <div className="AccessTime-Right">
                                                  <AccessTimeOutlinedIcon
                                                    style={
                                                      Styles.AcessTimeIconRight
                                                    }
                                                  />
                                                  <Typography
                                                    variant="caption"
                                                    style={Styles.timecolor}
                                                  >
                                                    {moment(
                                                      message.createdtime
                                                    ).format("LT")}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                        <div>
                                          <Avatar sx={Styles.AdminAvatar}>
                                            {usersName.charAt(0)}
                                          </Avatar>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}

                        {Check && cancelBtn ? (
                          <UserIncome
                            usersName={usersName}
                            email={email}
                            userId={userId}
                            socket={socket}
                            checkFunc={checkFunc}
                            cancelFunc={cancelFunc}
                            state={state}
                          />
                        ) : null}
                      </div>

                      <div className="chat-box-container">
                        {(!Check && onlinestatus !== "offline") ||
                        !cancelUser === 2 ? (
                          <SelectedFiles
                            files={files}
                            removeImage={removeImage}
                          />
                        ) : null}

                        {(!Check && onlinestatus !== "offline") ||
                        !cancelUser === 2 ? (
                          <>
                            {typingSts ? (
                              <div style={Styles.typeLetter} className="typing">
                                <p style={Styles.letters}>{typingSts}</p>{" "}
                                <div
                                  style={{ marginLeft: "5px" }}
                                  className="chat-bubble"
                                >
                                  <div className="chat-typing">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {onlinestatus !== "offline" ? (
                              <>
                                <div className="tag-container">
                                  {tagValue?.map((items) => {
                                    return (
                                      <div>
                                        <p
                                          style={Styles.tagMessages}
                                          onClick={() => setMessage(items.tag)}
                                        >
                                          {items.tag}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>

                                <div className="whole-footer">
                                  <SendMessageContainer
                                    idvalue={idvalue}
                                    message={message}
                                    handleEmoji={handleEmoji}
                                    handleOnEnter={handleOnEnter}
                                    handleFileUpload={handleFileUpload}
                                  />

                                  <div className="footer-sendIcon">
                                    {load ? (
                                      <CircularProgress {...sendLoad} />
                                    ) : (
                                      <Button
                                        variant="text"
                                        component="label"
                                        onClick={sendMessage}
                                        sx={Styles.sendButton}
                                      >
                                        <SendIcon
                                          style={Styles.sendIconstyle}
                                        />
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {/* <Grid container sx={Styles.sendMessageContainer}>
                          <Grid xs={12} sx={Styles.containerTExt}>
                            <Typography sx={Styles.offlineText}>
                              {cancelUser === 2 || errors
                                ? ""
                                : onlinestatus === "offline"
                                ? ""
                                : null}
                            </Typography>
                          </Grid>
                        </Grid> */}
                      </div>
                    </div>
                  </Grid>
                ) : null}

                {showNav ? (
                  <UserProfile
                    usersName={usersName}
                    email={email}
                    set_show={set_show}
                    show_method={show_method}
                    daweropen={dawerOpen}
                    showNav={showNav}
                    socket={socket}
                    online_status={onlinestatus}
                  />
                ) : null}
              </Grid>

              {matches ? (
                <ResponsiveBottom
                  value={value}
                  handleValueChange={handleValueChange}
                  handleContentChange={handleContentChange}
                />
              ) : null}

              {open ? (
                <CopiedText open={open} handleClose={handleClose} />
              ) : null}
            </Box>
            <ImageContainer
              imagesArray={imagesArray}
              isViewerOpen={isViewerOpen}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Messages;
