import React, { useState, useEffect } from "react";
import { Box, Typography, AppBar, Toolbar, Card, Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Styles } from "../../constant/styles";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";

export default function AppHeader({
  socket,
  set_show,
  usersName,
  online_status,
  handleMobileClose,
  email,
  setTruFum,
  recentChat,
}) {
  const matches = useMediaQuery("(max-width:900px)");

  const [searchPop, setSearchPop] = useState(false);
  const [morePop, setMorePop] = useState(false);
  const [onLine, setOnLine] = useState(online_status);
  const [mbpopup, setMbPopup] = useState(false);

  const popup = () => {
    setSearchPop(!searchPop);
    setMorePop(!morePop);
  };

  useEffect(() => {
    socket.on("LeaveUsers", (data) => {
      if (data.email === email) {
        setOnLine(data.online_status);
      }
    });

    socket.on("IdleUsers", (data) => {
      setOnLine(data.online_status);
    });

    socket.on("Active", (data) => {
      if (data.email === email) {
        setOnLine(data.online_status);
      }
    });
  }, [online_status]);

  return (
    <AppBar sx={Styles.appBar} elevation={0} position="relative">
      <Toolbar sx={{ mt: "15px" }}>
        {matches ? (
          <ArrowBackIosNewOutlinedIcon
            sx={Styles.mbArrowIcon}
            onClick={() => handleMobileClose()}
          ></ArrowBackIosNewOutlinedIcon>
        ) : null}
        <Avatar sx={Styles.headerAvatar}>{usersName.charAt(0)}</Avatar>

        <Typography noWrap sx={Styles.senderName} onClick={() => set_show()}>
          {usersName}{" "}
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          <FiberManualRecordIcon
            style={{
              color:
                online_status === "online"
                  ? "#49be25"
                  : online_status === "Idle"
                  ? "orange"
                  : "#7a7879",
              fontSize: "10px",
            }}
          />
        </Box>

        <Box className="nav_icons">
          {/* <SearchOutlinedIcon
            onClick={() => setSearchPop(!searchPop)}
            style={Styles.searchAppBar}
          /> */}
          <Box className="search-top-box">
            {searchPop && (
              <Box className="pop-up">
                <input
                  placeholder="search.."
                  className="pop-input-box"
                  disableUnderline
                />
              </Box>
            )}
          </Box>
          {!matches ? (
            <PersonOutlineRoundedIcon
              onClick={() => set_show()}
              style={Styles.searchAppBar}
            ></PersonOutlineRoundedIcon>
          ) : null}
          <MoreHoriz
            onClick={() => {
              setMorePop(!morePop);
            }}
            open={morePop}
            onClose={popup}
            style={Styles.searchAppBar}
          ></MoreHoriz>
          {morePop && (
            <Box className="drop-down-menu">
              {matches ? (
                <MenuItem sx={Styles.menuItemColor} onClick={() => set_show()}>
                  View profile
                  <ListItemIcon style={Styles.dropDownItem}>
                    <PersonOutlineRoundedIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              ) : null}
              {/* {matches ?
                null :
              <MenuItem sx={Styles.menuItemColor}>
                Archive
                <ListItemIcon style={Styles.DropdownItem}>
                  <Inventory2OutlinedIcon fontSize="12px" />
                </ListItemIcon>
              </MenuItem>} */}

              {matches ? null : (
                <MenuItem sx={Styles.menuItemColor}>
                  Muted
                  <ListItemIcon style={Styles.dropDownItem}>
                    <VolumeOffOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              )}

              <MenuItem
                sx={Styles.menuItemColor}
                onClick={() => setMbPopup(true)}
              >
                Delete
                <ListItemIcon style={Styles.DropdownItem}>
                  <DeleteOutlineIcon fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Box>
          )}

          {mbpopup && (
            <div>
              <Card className="delete-popup">
                <DialogTitle
                  style={{ color: "black", fontSize: "15px" }}
                  id="alert-dialog-title"
                >
                  {"Are you sure want to delete this chat?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    style={{ fontSize: "12px" }}
                    onClick={() => setMbPopup(!mbpopup)}
                  >
                    Disagree
                  </Button>
                  <Button
                    style={{ fontSize: "12px" }}
                    onClick={() => setMbPopup(!mbpopup)}
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Card>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
