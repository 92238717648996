import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Typography } from "@mui/material";
import {
  TextPlain,
  Pdf,
  Docx,
  Xlsx,
  Xls,
  Csv,
} from "../../constant/fileTypeExt";
import { BaseUrl } from "../../constant/apipath";
import { FileIcon, defaultStyles } from "react-file-icon";
import ArrowCircleDownOutlined from "@mui/icons-material/ArrowCircleDownOutlined";
import JsFileDownloader from "js-file-downloader";
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";

const FileContainer = ({ message }) => {
  const [fileId, setFileId] = useState("");

  const prop = {
    percent: 100,
    colorCircle: "black",
    fontColor: "black",
    fontWeight: 300,
    size: 27,
    stroke: 10,
    speed: 100,
    rotation: -90,
    opacity: 8,
    // top: -10,
    animationOff: false,
    inverse: false,
    round: false,
    number: false,
    linearGradient: ["rgb(230, 235, 245)"],
  };

  const loadAnimation = (postImg, index) => {
    return (
      <>
        <div style={{ position: "absolute" }}>
          {fileId === postImg ? (
            <CircularProgressBar
              {...prop}
              // className="download-icon-css"
              onClick={() => {
                setFileId(postImg.id);
                handleDownload(
                  postImg.src
                    ? postImg.src
                    : BaseUrl +
                        "/images/" +
                        postImg.user_id +
                        "/" +
                        postImg.source,
                  postImg.user_id ? postImg.original_name : postImg.name
                );
              }}
              style={{
                fontSize: "23px",
                cursor: "pointer",
                position: "absolute",
                // color: message.from_to === "admin" ? "#fff" : "",
              }}
            />
          ) : null}
        </div>
      </>
    );
  };

  const handleDownload = (data, filename) => {
    new JsFileDownloader({
      url: data,
      filename: filename,
    })
      .then(function (response) {})
      .catch(function (error) {});
  };
  return (
    <div>
      {message.postimages.map((postImg, index) => {
        return (
          <>
            {postImg.ext === TextPlain ||
            postImg.ext === Pdf ||
            postImg.ext === Xls ||
            postImg.ext === Xlsx ||
            postImg.ext === Docx ||
            postImg === Csv ? (
              <div style={{ marginTop: "10px" }}>
                {postImg !== "" ? (
                  <div className="file-details-container">
                    <div
                      style={{
                        height: 30,
                        width: 30,
                      }}
                    >
                      <FileIcon
                        extension={postImg.ext}
                        {...defaultStyles[postImg.ext]}
                      />
                    </div>
                    <div className="name-container">
                      <Typography className="file-name" variant="subtitle2">
                        {postImg.name
                          ? postImg.name
                          : postImg.original_name.substring(0, 4) +
                            ".." +
                            postImg.original_name.slice(-8)}
                      </Typography>

                      {parseInt(Math.round(postImg.size / 1024)) <= 1024 ? (
                        <Typography className="file-size" variant="subtitle2">
                          {parseInt(Math.round(postImg.size / 1024))} Kb
                        </Typography>
                      ) : (
                        <Typography className="file-size" variant="subtitle2">
                          {parseInt(
                            postImg.size / Math.pow(1024, 2).toFixed(5)
                          )}{" "}
                          Mb
                        </Typography>
                      )}
                    </div>
                    <div className="download-btn">
                      <div>
                        {loadAnimation(postImg.id)}
                        <ArrowCircleDownOutlined
                          onClick={() => {
                            setFileId(postImg.id);
                            handleDownload(
                              postImg.src
                                ? postImg.src
                                : BaseUrl +
                                    "/images/" +
                                    postImg.user_id +
                                    "/" +
                                    postImg.source,
                              postImg.user_id
                                ? postImg.original_name
                                : postImg.name
                            );
                          }}
                          style={{
                            fontSize: "24px",
                            cursor: "pointer",
                            position: "relative",
                            bottom: "0",
                            top: "1.5",
                            left: "1",
                          }}
                        />
                        {/* } */}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default FileContainer;
