import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Grid, Typography, Accordion, Card, Drawer } from "@mui/material";
import { Png, webp, Jpeg, Jpg, Tiff, Gif } from "../../constant/fileTypeExt";
import { BaseUrl, ApiUrl, GetFiles } from "../../constant/apipath";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Styles } from "../../constant/styles";
import Avatar from "@mui/material/Avatar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import Image from "@mui/icons-material/Image";
import Divider from "@mui/material/Divider";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import axios from "axios";
import { FileIcon, defaultStyles } from "react-file-icon";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import FsLightbox from "fslightbox-react";
import JsFileDownloader from "js-file-downloader";

export default function UserProfile({
  email,
  usersName,
  // showNav,
  set_show,
  show_method,
  socket,
  online_status,
}) {
  const [expanded, setExpanded] = useState(false);
  const [postImages, setPostImages] = useState([]);
  const [postFiles, setPostFiles] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [mbDawer, setMbDawer] = useState(false);
  var fileDownload = require("js-file-download");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const token = localStorage.getItem("token");
  const openImageViewer = (img) => {
    const imgSrc =
      img && img.length > 0
        ? img.map((items) => {
            return BaseUrl + "/images/" + items.user_id + "/" + items.source;
          })
        : "undefined";
    if (imgSrc) {
      setImagesArray([...imgSrc]);
    }
    setIsViewerOpen(!isViewerOpen);
  };

  const handleDownload = (data, filename) => {
    // new JsFileDownloader({
    //   url: data,
    //   filename: filename,
    // });
    fileDownload(data, `${filename}`);
  };

  const useStyles = makeStyles((theme) => ({
    imageList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)",
    },
    listItems: {
      height: 200,
      width: 200,
      padding: 3,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getFileApi();
  }, [email]);

  useEffect(() => {
    socket.on("adminreceiver_message", (data) => {
      getFileApi();
    });
  }, [socket]);

  const getFileApi = async () => {
    axios({
      method: "post",
      url: BaseUrl + ApiUrl + GetFiles,
      // headers: {
      //   Authorization: token,
      // },
      data: {
        Email: email,
      },
    })
      .then(async (response) => {
        if (
          response.data &&
          response.data.chats &&
          response.data.chats.length > 0
        ) {
          getFiles(response.data.chats);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getFiles = async (files) => {
    if (files && files.length > 0) {
      await files.map((items) => {
        if (
          items.fileType === Png ||
          items.fileType === Jpeg ||
          items.fileType === Jpg ||
          items.fileType === webp ||
          items.fileType === Gif
        ) {
          setPostImages((state) => [...state, items]);
        } else {
          setPostFiles((state) => [...state, items]);
        }
      });
    }
  };

  return (
    <>
      <Drawer anchor="right" open={set_show}>
        <div className="Dawer-width">
          <div className="Dawer-closeIcon">
            <CloseIcon onClick={() => show_method()} fontSize="small" />
          </div>
          <div className="account-profile">
            <Avatar size={30} sx={{ mr: 1, width: 68, height: 68 }}>
              {usersName.charAt(0)}
            </Avatar>
          </div>
          <Typography sx={{ mr: 1, pt: 1 }} align="center" variant="body1">
            {usersName}{" "}
          </Typography>
          <div className="status-container">
            <FiberManualRecordIcon
              fontSize="1px"
              style={{
                color:
                  online_status === "online"
                    ? "#49be25"
                    : online_status === "Idle"
                    ? "orange"
                    : "#7a7879",
              }}
            />
            <Typography
              className="name"
              variant="body2"
              sx={{ color: "#9bb3d9" }}
            >
              {online_status === "online"
                ? "Active"
                : online_status === "Idle"
                ? "Idle"
                : "Offline"}
            </Typography>
          </div>

          <div className="divider-container">
            <Divider />
          </div>
          <div className="gg">
            <div className="description-container">
              <Typography
                className="description"
                variant="body2"
                sx={{ color: "#909dbc" }}
                paragraph0
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
            </div>
            <div className="accordian-container">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{ backgroundColor: "#f5f7fb" }}
                disableGutters
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="accordian-center">
                    <PersonOutlineIcon fontSize="10px" />
                    <Typography variant="subtitle2" sx={{ ml: "10px" }}>
                      About
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
                  <div className="detail">
                    <Typography variant="body2" sx={{ color: "#909dbc" }}>
                      Name
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      {usersName}{" "}
                    </Typography>
                  </div>
                  <div className="detail">
                    <Typography variant="body2" sx={{ color: "#909dbc" }}>
                      Email
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      {email}{" "}
                    </Typography>
                  </div>
                  <div className="detail">
                    <Typography variant="body2" sx={{ color: "#909dbc" }}>
                      Time
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      11.40am
                    </Typography>
                  </div>
                  <div className="detail">
                    <Typography variant="body2" sx={{ color: "#909dbc" }}>
                      Location
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      california , USA
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={Styles.accordian2}
                disableGutters
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="accordian-center">
                    <AttachFileOutlinedIcon fontSize="12px" />
                    <Typography variant="subtitle2" sx={{ ml: "10px" }}>
                      Attached Files
                    </Typography>
                  </div>
                </AccordionSummary>
                {postFiles.length === 0 ? (
                  <Typography
                    variant="subtitle2"
                    sx={{ ml: "20px", color: "#909dbc", paddingBottom: 2 }}
                  >
                    No Files Found
                  </Typography>
                ) : (
                  <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
                    {postFiles.map((items) => {
                      return (
                        <>
                          <div className="details-container">
                            <Card className="attachment-card">
                              <div className="file-details-container">
                                <div className="file-details">
                                  <FileIcon
                                    extension={items.ext}
                                    {...defaultStyles[items.ext]}
                                  />
                                </div>
                                <div className="name-container">
                                  <Typography
                                    className="file-name"
                                    variant="subtitle2"
                                  >
                                    {items.original_name}
                                  </Typography>
                                  <Typography
                                    className="file-name"
                                    variant="body2"
                                  >
                                    12Mb
                                  </Typography>
                                </div>
                              </div>

                              <div className="file-details-container">
                                <div>
                                  <SaveAltIcon
                                    onClick={() =>
                                      handleDownload(
                                        items.src
                                          ? items.src
                                          : BaseUrl +
                                              "/images/" +
                                              items.user_id +
                                              "/" +
                                              items.source,
                                        items.original_name
                                      )
                                    }
                                  />
                                </div>
                                <div className="name-container">
                                  <MoreHorizIcon />
                                </div>
                              </div>
                            </Card>
                          </div>
                        </>
                      );
                    })}
                  </AccordionDetails>
                )}
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={Styles.accordian2}
                disableGutters
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="accordian-center">
                    <Image fontSize="12px" />
                    <Typography variant="subtitle2" sx={{ ml: "10px" }}>
                      Attached Images
                    </Typography>
                  </div>
                </AccordionSummary>

                {postImages.length === 0 ? (
                  <Typography
                    variant="subtitle2"
                    sx={{ ml: "20px", color: "#909dbc", paddingBottom: 2 }}
                  >
                    No Images Found
                  </Typography>
                ) : (
                  <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
                    <ImageList className={classes.imageList} cols={2.5}>
                      {postImages.map((item, index) => {
                        return (
                          <ImageListItem
                            onClick={() => openImageViewer(postImages)}
                            className={classes.listItems}
                            key={item.source}
                          >
                            <img
                              src={
                                BaseUrl +
                                "/images/" +
                                item.user_id +
                                "/" +
                                item.source
                              }
                              alt={item.title}
                            />
                          </ImageListItem>
                        );
                      })}
                    </ImageList>
                  </AccordionDetails>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </Drawer>
      <FsLightbox toggler={isViewerOpen} sources={imagesArray} />
    </>
  );
}
