export const Styles = {
  imageContainer: {
    maxHeight: 35,
    maxWidth: 35,
  },
  tagMessages: {
    fontSize: "13px",
    backgroundColor: "#deddd9",
    padding: 10,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: "12px",
  },
  typeLetter: {
    marginBottom: "-30px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
  fileList: {
    fontSize: "13px",
    wordBreak: "break-word",
    minWidth: "8rem",
    float: "left",
  },
  letters: {
    marginLeft: "30px",
    color: "green",
    fontWeight: "bold",
    fontSize: "17px",
  },
  fileListFile: {
    fontSize: "11px",
    wordBreak: "break-word",
    minWidth: "8rem",
    float: "left",
    paddingTop: "21px",
    // display: '-webkit-box',
    // textAlign: 'center',
    // overflow: "hidden",
    // '-webkit-line-clamp': '2',
    // '-webkit-box-orient': 'vertical',
    // height: 30
  },

  fileContainer: {
    // maxHeight: 35,
    // maxWidth: 35,
    // marginLeft: 16,
    maxWidth: "50px",
    marginLeft: "2px",
    marginTop: "16px",
    marginBottom: "-15px",
  },
  selectFile: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 2,
    paddingRight: 2,
    // height: 85,
    maxWidth: "90%",
  },
  imageGrid: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "auto auto",
  },

  filesContainer: {
    display: "flex",
    gap: "0.7rem",
    marginBottom: "0.5rem",
  },
  arrowIcon: {
    cursor: "pointer",
    color: "blue",
    fontSize: "30px",
  },
  msgContain: { display: "flex", justifyContent: "flex-end" },
  flexDirections: {
    display: "flex",
    flexDirection: "row",
  },
  downloadButton: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    backgroundColor: "red",
    borderRadius: 15,
    position: "absolute",
    color: "white",
    fontSize: 20,
  },
  docIcon: {
    fontSize: "80px",
    color: "#ccd8db",
  },
  styleModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
  },
  cardleftcolor: {
    backgroundColor: "rgb(230, 235, 245)",
    padding: "1rem",
    position: "relative",
  },
  cardrightcolor: {
    backgroundColor: "#3399cc",
    padding: "1rem",
    position: "relative",
  },
  Profileactiveicon: { color: "#49be25", fontSize: "10px" },
  Messageavatar: {
    width: "2.2rem",
    height: "2.2rem",
    marginRight: "0px",
    textTransform: "uppercase",
    fontSize: "20px",
  },
  AdminAvatar: {
    width: "2.2rem",
    height: "2.2rem",
    marginRight: "0px",
    marginTop: "0px",
    backgroundColor: "orange",
    textTransform: "uppercase",
    fontSize: "20px",
  },

  DropdownItem: { display: "flex", justifyContent: "flex-end", flex: "1" },
  AcessTimeIconLeft: {
    fontSize: "14px",
    marginTop: "2px",
    // color: "#ededed",
  },
  AcessTimeIconRight: {
    fontSize: "14px",
    marginTop: "2px",
    color: "#ededed",
  },
  dropDownItem: {
    display: "flex",
    justifyContent: "flex-end",
    flex: "1",
  },

  timecolor: { color: "#ededed" },

  searchInput: {
    padding: "5px",
    paddingLeft: "1vw",
    border: "1px solid #e6ebf5",
    color: "#898da4",
    backgroundColor: "#e6ebf5",
    color: "#8c91ab",
  },
  buttonBase: { width: "100%", height: 70 },
  baseButoon: { width: "100%", marginBottom: "-15px" },
  listItemAvatar: {
    width: "2.2rem",
    height: "2.2rem",
    marginRight: "3px",
    marginLeft: "8px",
    textTransform: "uppercase",
    fontSize: "20px",
  },
  onlineStatus: {
    color: "#49be25",
    fontSize: "14px",
    position: "absolute",
    marginLeft: "30px",
    marginTop: "-11px",
    zIndex: "1",
  },
  chatMessage: { color: "#a7afc4", width: "10vw" },
  TypeMessage: { color: "green" },

  badge: { position: "absolute", right: "10px" },
  appBar: {
    width: "100%",
    backgroundColor: "white",
    // mt: "1vw",
    borderBottomWidth: "1px",
    borderBottomColor: "#f1f1f6",
    borderBottomStyle: "solid",
  },
  searchAppBar: { padding: "0 8px", color: "#9b8a9c" },
  senderName: {
    color: "#000000",
    mr: 1,
    cursor: "pointer",
    fontWeight: "bold",
  },
  headerAvatar:{
    textTransform: "uppercase",
    marginRight:'10px',
  },
  mbArrowIcon: {
    color: "black",
    fontSize: "12px",
    padding: "5px",
    marginRight: "10px",
    marginTop: "-2px",
  },
  menuItemColor: { color: "black" },
  divider: { textAlign: "center", marginTop: 2 },
  receiverMessage: {
    fontSize: "15px",
    color: "white",
    marginTop: "5px",
  },
  sendMessageContainer: {
    p: 2,
    paddingRight: "20px",
    backgroundColor: "white",
    // paddingTop:"40px"
  },
  offlineText: {
    color: "#bf630d",
    fontWeight: "bold",
  },
  containerTExt: {
    display: "flex",
    justifyContent: "center",
  },
  support_sys_css: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textboxIconStyle: {
    color: "#3399cc",
    fontSize: "25px",
    marginLeft: "7px",
  },
  textAttachIconStyle: {
    color: "#3399cc",
    fontSize: "25px",
    marginLeft: "-4px",
  },
  dividercolor: {
    backgroundColor: "#e6ebf5",
    width: "50px",
    padding: "3px",
    fontSize: "13px",
    borderRadius: "3px",
  },
  sendIconstyle: { color: "white", fontSize: "20px", cursor: "pointer" },
  closeIconContainer: {
    position: "absolute",
    right: "2vw",
    top: "2vw",
    cursor: "pointer",
  },
  profileIcon: { height: 70, width: 70 },

  accordian2: { backgroundColor: "#f5f7fb", mt: "10px" },
  primaryText: { fontSize: "0.875rem", fontWeight: "bold" },
  sendButton: {
    minWidth: "100%",
    minHeight: "100%",
    borderRadius: "0.5vw",
    bgcolor: "#3399cc",
  },
  sidebarContentsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  middlePortion: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "150px",
  },
  iconscolor: { color: "#9a9196" },

  ProfileMore: { marginRight: "5px", fontSize: "19px", marginTop: "2px" },
  helpContents: { marginTop: "10px", color: "#52575a", cursor: "pointer" },
  selectDropdown: {
    height: "22px",
    color: "black",
    backgroundColor: "#d6dfe2",
    fontSize: "12px",
  },

  noteSecondHeader: {
    fontSize: "16px",
    fontWeight: "500",
  },
  noteFirst: {
    display: "flex",
    paddingTop: 15,
    paddingBottom: 15,
  },
  primaryText: {
    fontSize: "0.875rem",
    fontWeight: "bold",
  },

  noteSecond: {
    display: "flex",
  },
  primaryTextNote: {
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  noteButton: {
    display: "flex",
    gap: "1rem",
  },
};
