import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, useScrollTrigger } from "@mui/material";
import { Styles } from "../../constant/styles";
import Button from "@mui/material/Button";
import axios from "axios";
import { BaseUrl, Admin, GetAdmin, adminEmail } from "../../constant/apipath";
import ListItemText from "@mui/material/ListItemText";

export default function UserIncoming({
  email,
  usersName,
  userId,
  socket,
  noticationcancel,
  noticationaccept,
  checkFunc,
  cancelFunc,
  state,
}) {
  const [adminUsers, setAdminUsers] = useState("");
  const [card, setCard] = useState(true);

  const adminEmail = localStorage.getItem("adminEmail");
  const currentAdmin = localStorage.getItem("adminId");
  const userType = localStorage.getItem("userType");
  const webId = localStorage.getItem("cmpId");
  const depId = localStorage.getItem("depId");
  // console.log("icomingstate", webId, depId, currentAdmin);
  const ink = localStorage.getItem("user_email");
  const updateUserAccept = (mail, user) => {
    socket.emit("userAccept", {
      email: ink,
      userId: socket.id,
      id: user,
      admin_Id: currentAdmin,
      adminEmail: adminEmail,
      depId: depId,
      userType: userType,
      webId: webId,
    });
    checkFunc();
    noticationaccept();
  };

  // console.log("currrrr", currentAdmin, depId, webId);

  useEffect(() => {
    getAdmins();
    socket.on("Accept", (data) => {
      setCard(false);
    });
    socket.on("Cancel", (data) => {
      setCard(false);
    });

    
  }, [socket, card]);

  const getAdmins = () => {
    axios
      .get(BaseUrl + Admin + GetAdmin + adminEmail)
      .then(function (data) {
        if (data.data.adminUsers) {
          setAdminUsers(data.data.adminUser);
        }
      })
      .catch((err) => console.log("err", err));
  };
  const updateUserCancel = (mail, user) => {
    socket.emit("userCancel", {
      email: ink,
      userId: socket.id,
      id: user,
      adminId: currentAdmin,
      depId: depId,
      webId: webId,
      userType: userType,
    });
    cancelFunc();
    noticationcancel();
  };

  return (
    <>
      {card ? (
        <Card
          elevation={3}
          style={{ marginTop: "30px" }}
          // className="notication-card"
        >
          <CardContent>
            <Typography variant="h6">New live chat from {usersName}</Typography>

            <Typography>
              To respond, Click Accept chat or Cancel chat.
            </Typography>
            <Typography style={Styles.noteSecondHeader}>
              Vistor Question
            </Typography>
            <Typography>
              Yes,I have a question about the product on your website.
            </Typography>
            <Typography style={Styles.noteSecondHeader}>
              Vistor Information
            </Typography>
            <div style={Styles.noteFirst}>
              <ListItemText
                primary={<Typography sx={Styles.primaryText}>Name</Typography>}
                secondary={<Typography variant="body2">{usersName}</Typography>}
              />
              <ListItemText
                primary={<Typography sx={Styles.primaryText}>Email</Typography>}
                secondary={<Typography variant="body2">{email}</Typography>}
              />
            </div>
            <div style={Styles.noteButton}>
              <Button
                className="chat-accept-btn"
                variant="contained"
                onClick={() => {
                  updateUserAccept(email, userId);
                }}
              >
                Accept
              </Button>
              <Button
                className="chat-cancel-btn"
                variant="contained"
                onClick={() => {
                  updateUserCancel(email, userId);
                }}
              >
                Cancel
              </Button>
            </div>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}
