export const Jpg = "image/jpg";
export const Jpeg = "image/jpeg";
export const Png = "image/png";
export const Tiff = "image/tiff";
export const Gif = "image/gif";
export const webp = "image/webp";

//Document

export const TextPlain = "txt";
export const Pdf = "pdf";
export const Docx ="docx";
export const Xls = "xls";
export const Xlsx = "xlsx";
export const Csv = "csv"

