import React, { useState } from "react";
import { IconButton, ListItemIcon, Paper, MenuList } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  MenuItem,
  Divider,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { orgId, website } from "../../constant/config";

export default function AdminLogout({
  admin_logoutSetting,
  admin_logoutProfile,
}) {
  const matches = useMediaQuery("(max-width:899px)");

  const userType = localStorage.getItem("userType");

  console.log("userty", userType);

  const [logout, setLogout] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const removeAdminEmail = () => {
    setOpen(true);
  };

  const RemoveEmail = () => {
    localStorage.removeItem("adminEmail");
    localStorage.removeItem("adminId");
    localStorage.removeItem("userType");
    localStorage.removeItem("token");
    localStorage.removeItem("supSys");
    localStorage.removeItem("cmpId");
    localStorage.removeItem("depId");
    navigate(`/`, { replace: true });
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      {!matches ? (
        <div className="profile-nav">
          {logout && (
            <Paper className="logout-proflie">
              <MenuList>
                <MenuItem onClick={() => admin_logoutProfile()}>
                  <Typography variant="inherit"> Profile</Typography>
                  &nbsp;&nbsp;&nbsp;
                  <ListItemIcon>
                    <AccountBoxOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={() => admin_logoutSetting()}>
                  <Typography variant="inherit"> Setting</Typography>
                  &nbsp;&nbsp;
                  <ListItemIcon>
                    <SettingsOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
                <Divider></Divider>
                <MenuItem onClick={() => removeAdminEmail()}>
                  <Typography variant="inherit" noWrap>
                    {" "}
                    Log out
                  </Typography>
                  &nbsp;&nbsp;
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              </MenuList>
            </Paper>
          )}
          <IconButton sx={{ mb: 1, ml: 1 }}>
            <Avatar
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaWfFvR1wfnWUVbvkjyvXZmm_DFTmDdY1yK0dgEquvSemeXe12ImQ_R_Kx_Iz0DCylnZE&usqp=CAU"
              onClick={() => setLogout(!logout)}
            />
          </IconButton>
        </div>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to log out?
          </Typography>
          <Box sx={{ backgroundColor: "white", marginTop: 2 }}>
            <Button onClick={RemoveEmail} variant="contained">
              Yes
            </Button>
            <Button
              style={{ marginLeft: "30px" }}
              variant="contained"
              onClick={handleClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
