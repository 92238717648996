import React from "react";
import {
  Alert,
  Container,
  Drawer,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { Styles } from "../../constant/styles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SmsIcon from "@mui/icons-material/Sms";
import SettingsIcon from "@mui/icons-material/Settings";

const DrawerContent = ({ handleContentChange }) => {
  const drawerWidth = 75;

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Container sx={Styles.sidebarContentsContainer}>
        <IconButton sx={{ mt: 2 }} onClick={() => window.location.reload(true)}>
          <img src={require("../../img/iboost.png")} className="reel" alt="" />
        </IconButton>
        <Box sx={Styles.middlePortion}>
          <Tooltip title="Profile" arrow placement="top">
            <IconButton onClick={() => handleContentChange("profile")}>
              <PersonOutlineIcon sx={Styles.iconscolor} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Chats" arrow placement="left">
            <IconButton onClick={() => handleContentChange("chat")}>
              <SmsIcon sx={Styles.iconscolor} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings" arrow placement="left">
            <IconButton onClick={() => handleContentChange("settings")}>
              <SettingsIcon sx={Styles.iconscolor} />
            </IconButton>
          </Tooltip>
        </Box>
      </Container>
    </Drawer>
  );
};

export default DrawerContent;
