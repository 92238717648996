import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { BaseUrl, Admin, WebUrl, GetAdmin } from "../../constant/apipath";
import { Grid, Typography, Accordion, IconButton } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import { Styles } from "../../constant/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function AdminProfile({ socket }) {
  const [navProfileMore, setNavProfileMore] = useState(false);
  const [AdminUsers, setAdminUsers] = useState({});
  const [profilePanelExpanded, setProfilePanelExpanded] =
    useState("profilePanel1");
  const navigate = useNavigate();

  const handleAccordian2 = (panel) => (event, isExpanded) => {
    setProfilePanelExpanded(isExpanded ? panel : false);
  };

  const adminEmail = localStorage.getItem("adminEmail");

  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = () => {
    axios
      .get(BaseUrl + Admin + GetAdmin + adminEmail)
      .then(function (data) {
        setAdminUsers(data.data.adminUser);
      })
      .catch((err) => console.log("err", err));
  };

  const RemoveEmail = () => {
    socket.emit("adminLogOut", {
      email: adminEmail,
      id: socket.id,
    });
    setNavProfileMore(false);
    localStorage.removeItem("adminEmail");
    localStorage.removeItem("adminId");
    localStorage.removeItem("userType");
    localStorage.removeItem("token");
    localStorage.removeItem("supSys");
    localStorage.removeItem("cmpId");
    localStorage.removeItem("depId");
    navigate(`/`, { replace: true });
    window.location.reload();
  };

  return (
    <Box>
      <Grid container className="profile-header">
        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
          <Typography variant="h6" sx={{ mt: -0.5, ml: 2 }}>
            My Profile
          </Typography>
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
          <IconButton disableRipple>
            <MoreVertOutlinedIcon
              onClick={() => setNavProfileMore(!navProfileMore)}
              open={navProfileMore}
              style={Styles.ProfileMore}
            />
          </IconButton>

          {navProfileMore && (
            <Box className="Logout-Admin-proflie">
              {/* <MenuItem
                style={{ padding: "1px", marginTop: "2px", marginTop: "-6px" }}
              >
                Profile
                <IconButton style={{ marginLeft: "28px" }}>
                  <AccountBoxOutlinedIcon fontSize="small" />
                </IconButton>
              </MenuItem>
              <MenuItem style={{ padding: "1px", marginTop: "-5px" }}>
                Setting
                <IconButton style={{ marginLeft: "24px" }}>
                  <SettingsOutlinedIcon fontSize="small" />
                </IconButton>
              </MenuItem> */}
              {/* <Divider></Divider> */}
              <MenuItem
                onClick={RemoveEmail}
                style={{ padding: "1px", margin: "-10px 0px -11px" }}
              >
                Log out
                <IconButton style={{ marginLeft: "23px" }}>
                  <LogoutIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid>
        <Box className="account-profile">
          <Avatar sx={Styles.profileIcon} />
        </Box>
        <Typography className="adminprofileName">
          {AdminUsers?.first_name}
        </Typography>
        <Box className="status-containeradmin">
          <FiberManualRecordIcon fontSize="1px" style={{ color: "#49be25" }} />
          <Typography
            className="name"
            variant="body2"
            sx={{ color: "#9bb3d9" }}
          >
            Active
          </Typography>
        </Box>
        <Box className="divider-container">
          <Divider />
        </Box>
        <Box className="profile-accordians-container">
          <Box className="description-container">
            <Typography
              className="description"
              variant="body2"
              sx={{ color: "#909dbc" }}
              paragraph0
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
          </Box>
          <Box className="accordian-container">
            <Accordion
              expanded={profilePanelExpanded === "profilePanel1"}
              onChange={handleAccordian2("profilePanel1")}
              sx={{ backgroundColor: "#f5f7fb" }}
              disableGutters
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box className="accordian-center">
                  <PersonOutlineIcon fontSize="10px" />
                  <Typography variant="subtitle2" sx={{ ml: "10px" }}>
                    About
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#ffffff" }}>
                <Box className="detail">
                  <Typography variant="body2" sx={{ color: "#909dbc" }}>
                    Name
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {AdminUsers?.first_name}
                  </Typography>
                </Box>
                <Box className="detail">
                  <Typography variant="body2" sx={{ color: "#909dbc" }}>
                    Email
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {AdminUsers?.email ? AdminUsers?.email : adminEmail}
                  </Typography>
                </Box>
                <Box className="detail">
                  <Typography variant="body2" sx={{ color: "#909dbc" }}>
                    Time
                  </Typography>
                  {/* <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {moment(AdminUsers.createdAt).format("L LTS")}
                  </Typography> */}
                </Box>
                <Box className="detail">
                  <Typography variant="body2" sx={{ color: "#909dbc" }}>
                    Support System
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {AdminUsers?.support_system}
                  </Typography>
                </Box>
                <Box className="detail">
                  <Typography variant="body2" sx={{ color: "#909dbc" }}>
                    Location
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    california , USA
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
