import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Tooltip, Button } from "@mui/material";
import { Styles } from "../../constant/styles";
import InputEmoji from "react-input-emoji";
import SendIcon from "@mui/icons-material/Send";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

const sendMessageContainer = ({
  idvalue,
  message,
  handleEmoji,
  handleOnEnter,
  handleFileUpload,
}) => {
  return (
    <>
      <div className="text-emoji">
        <InputEmoji
          key={idvalue}
          value={message}
          borderRadius={12}
          borderColor="#cdd7df"
          onChange={(e) => {
            handleEmoji(e, idvalue);
          }}
          emoji
          cleanOnEnter
          onFocus
          shouldReturn={true}
          onEnter={handleOnEnter}
          placeholder="Enter the message.... "
        />
      </div>
      <div className="footer-imgIcon">
        <Tooltip title="Attached image" arrow>
          <Button variant="text" component="label" style={{ minWidth: "100%" }}>
            <ImageOutlinedIcon style={Styles.textboxIconStyle} />
            <input
              onChange={handleFileUpload}
              type="file"
              accept="image/*"
              hidden
              multiple
            />
          </Button>
        </Tooltip>
      </div>

      <div className="footer-attachIcon">
        <Tooltip title="Attached file" arrow>
          <Button variant="text" component="label" style={{ minWidth: "100%" }}>
            <AttachFileOutlinedIcon />
            <input
              onChange={handleFileUpload}
              type="file"
              accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain"
              hidden
              multiple
            />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default sendMessageContainer;
